@font-face {
  font-family: 'Open Sans';
  src: url(../assets/font/OpenSans-Regular.ttf);
}

$animation-duration: 300ms;

.socure-websdk {
  .application-root {
    display: flex;
    flex-direction: column;
  }

  .flex {
    display: flex !important;
  }

  .flex0 {
    flex: 0 0 auto !important;
  }

  .flex1 {
    flex: 1 1 auto !important;
  }

  .justify-left {
    justify-content: left;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-right {
    justify-content: right;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .justify-space-between {
    justify-content: space-between;
  }

  .justify-space-around {
    justify-content: space-around;
  }

  .align-left {
    align-items: flex-end;
  }

  .align-center {
    align-items: center;
  }

  .align-right {
    align-items: flex-end;
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-col {
    flex-direction: column;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .p-auto {
    padding: auto;
  }

  .m-auto {
    margin: auto;
  }

  .p-05 {
    padding: 0.5rem;
  }

  .m-05 {
    margin: 0.5rem;
  }

  .pt-05 {
    padding-top: 0.5rem !important;
  }

  .pb-05 {
    padding-bottom: 0.5rem !important;
  }

  .pr-05 {
    padding-right: 0.5rem !important;
  }

  .pl-05 {
    padding-left: 0.5rem !important;
  }

  .py-05 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }

  .px-05 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .mt-05 {
    margin-top: 0.5rem !important;
  }

  .mb-05 {
    margin-bottom: 0.5rem !important;
  }

  .mr-05 {
    margin-right: 0.5rem !important;
  }

  .ml-05 {
    margin-left: 0.5rem !important;
  }

  .my-05 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  .mx-05 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .p-1 {
    padding: 1rem;
  }

  .m-1 {
    margin: 1rem;
  }

  .pt-1 {
    padding-top: 1rem !important;
  }

  .pb-1 {
    padding-bottom: 1rem !important;
  }

  .pr-1 {
    padding-right: 1rem !important;
  }

  .pl-1 {
    padding-left: 1rem !important;
  }

  .py-1 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  .px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .mt-1 {
    margin-top: 1rem !important;
  }

  .mb-1 {
    margin-bottom: 1rem !important;
  }

  .mr-1 {
    margin-right: 1rem !important;
  }

  .ml-1 {
    margin-left: 1rem !important;
  }

  .my-1 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }

  .mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .p-2 {
    padding: 2rem;
  }

  .m-2 {
    margin: 2rem;
  }

  .pt-2 {
    padding-top: 2rem !important;
  }

  .pb-2 {
    padding-bottom: 2rem !important;
  }

  .pr-2 {
    padding-right: 2rem !important;
  }

  .pl-2 {
    padding-left: 2rem !important;
  }

  .py-2 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }

  .px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .mt-2 {
    margin-top: 2rem !important;
  }

  .mb-2 {
    margin-bottom: 2rem !important;
  }

  .mr-2 {
    margin-right: 2rem !important;
  }

  .ml-2 {
    margin-left: 2rem !important;
  }

  .my-2 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }

  .mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .h-100 {
    height: 100%;
  }

  .w-100 {
    width: 100%;
  }

  .transition-linear-default {
    transition: all linear $animation-duration;
  }

  .o-0 {
    opacity: 0;
  }

  .o-1 {
    opacity: 1;
  }

  .position-relative {
    position: relative;
  }

  .position-absolute {
    position: absolute;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  span.warning > div {
    display: inline-block;
    vertical-align: top;
  }

  /** Start: Powered by CSS */
  .powered-by-socure-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: inherit;
    margin-top: 50px;
  }

  .powered-by-socure-container .powered-by-socure-text {
    display: flex;
    align-items: center;
    color: #141b4d;
    margin: 0.4em;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 153.61%;
    width: 67px;
    height: 18px;
  }

  .powered-by-socure-container .socure-powered-by-logo {
    width: 69px;
    height: 28px;
  }

  /** End: Powered by CSS */

  /** Media queries for responsiveness **/
  .narrow-container {
    .redirect-divider {
      display: none !important;
    }
    .redirect-sms {
      border-bottom: 2px solid #dbe2e9;
    }
    .redirect-qr {
      width: 100%;
    }
  }

  .redirect-sms {
    max-width: 332px;
  }

  .redirect-qr {
    max-width: 332px;
  }
}
